// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Headroom from 'headroom.js';

const headroom = {
  init() {
    const headerEl = document.querySelector('header');
    if (headerEl) {
      const hr = new Headroom(document.querySelector('header'), { offset: 80 });
      hr.init();
    }
  },
};
export default headroom;
