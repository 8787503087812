// Import our CSS
import '@/css/app.css';
// import '@zachleat/table-saw';
import importAsync from './utils/importAsync';
import alpinejs from './global/alpinejs';
import lightbox from './global/glightbox';
import headroom from './global/headroom';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AsyncAlpine from 'async-alpine';

export type ComponentConfig = {
  promise: Promise<object>;
  callback?: CallableFunction;
};

const init = async function () {
  const Alpine = alpinejs.init();
  lightbox.init();
  headroom.init();
  // Note, we're using JS here for alpine stuff because it's easier to work with in relation to the DOM than TS
  AsyncAlpine.init(Alpine)
    .data('clientResults', () => import('./components/clientResults.js'))
    .data('additionalServiceDetailCards', () => import('./components/additionalServiceDetailCards.js'))
    .data('testimonialSectionVideo', () => import('./components/testimonialSectionVideo.js'))
    .data('testimonialSectionImage', () => import('./components/testimonialSectionImage.js'))
    .data('podcastPlayer', () => import('./components/podcastPlayer.js'))
    .data('tabbedServiceDetails', () => import('./components/tabbedServiceDetails.js'))
    .data('mainNav', () => import('./components/mainNav.js'))
    .data('dialog', () => import('./components/dialog.js'))
    .data('algoliaSearch', () => import('./components/algoliaSearch.js'))
    .data('heroTitleUnderline', () => import('./components/heroTitleUnderline.js'))
    .data('imageContentMirrorSteps', () => import('./components/imageContentMirrorSteps.js'))
    .data('horizontalCarouselSwiper', () => import('./components/horizontalCarouselSwiper'))
    .data('chapterTable', () => import('./components/chapterTable'))
    .start();
  Alpine.start();

  importAsync('[data-plyr]', 'plyr', true, true, true);

  // LEGACY JUNK

  const promises: ComponentConfig[] = [];

  document.querySelectorAll('[data-component]').forEach((el: Element) => {
    const componentList = (el as HTMLElement).dataset.component;
    if (!componentList) {
      return;
    }
    const components = componentList.split(',');
    components.forEach((component) => {
      promises.push({ promise: import(`./components/${component}.js`) });
    });
  });

  /**
   * Wait for all components to be loaded
   */
  promises.forEach(function (config: ComponentConfig) {
    config.promise.then((c) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = c.default;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof c.default === 'function') {
        console.log('Calling c.default()');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        c.default();
      }
      if (config.callback) {
        console.debug('Invoking callback');
        config.callback(result);
      }
    });
  });
  return Promise.all(promises.map((p) => p.promise));
};

init().then(() => {
  console.log('Done!');
});

// Once the document is fully loaded, initialize global components
document.addEventListener('DOMContentLoaded', () => {
  console.debug('DOMContentLoaded');
  document.documentElement.classList.add('js-loaded');
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
