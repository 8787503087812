// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

const lightbox = {
  init() {
    window.GLightbox = GLightbox;
    // Docs: https://github.com/biati-digital/glightbox
    GLightbox({
      selector: '.glightbox',
      plyr: {
        config: {
          ratio: '16:9',
          youtube: {
            noCookie: true,
            rel: 0,
            showinfo: 0,
            modestbranding: 1,
            iv_load_policy: 3,
          },
          vimeo: {
            byline: false,
            portrait: false,
            title: false,
            speed: true,
            transparent: false,
          },
        },
      },
    });
  },
};
export default lightbox;
